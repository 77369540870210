
.contact-methods-wrapper {
    justify-content: center;
    align-items: center;

    .contact-method {
        display: flex;
        position: relative;
        color: var(--global-color);
        padding-top: calc(var(--global-margin) * 0.6);
        padding-bottom: calc(var(--global-margin) * 0.6);
        margin-top: calc(var(--global-margin) * 0.6);
        margin-bottom: calc(var(--global-margin) * 0.6);
        font-size: 15px;
        max-width: 200px;

        &:not(.linkable) {

            &:hover {
                text-decoration: none;
                cursor: default;
            }
        }

        @media (min-width:1200px) {
            font-size: 20px;
        }

        &.web-link {
            .contact-method-text {
                color: var(--global-link-color);
                text-decoration: underline;

                &:hover{
                    text-decoration: none;
                }
            }
        }

        .contact-icon {
            margin-bottom: calc(var(--global-margin) * 0.5);
        }



    }

    .contact-separator {
        width: 125px;
        min-height: 2px;
        background-color: #fcba38;
        z-index: 2;
    }


    .contact-method-wrapper:last-of-type{
        .contact-separator {
            display: none;
        }


    }

}



